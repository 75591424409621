<script>
import appConfig from "@/app.config";
import PDFViewer from "../../../../layouts/pdf-viewer.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

// tinymce editor
// import Editor from "@tinymce/tinymce-vue";

// ckeditor
import CKEditor from "@ckeditor/ckeditor5-vue";
import CustomEditor from "@/custom/ckeditor/build/ckeditor";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Pengerjaan Tes Tipe 1",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PDFViewer,
    VueCountdown,
    // editor: Editor,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      id_jadwal: null,
      id_jadwal_detail: null,
      tipe: null,
      tipe_pengerjaan: null,

      is_petunjuk_soal_rich_text: false,
      content_petunjuk_soal_rich_text: "Silakan klik button petunjuk untuk memulai pengerjaan",

      editor: CustomEditor,
      editorData: "",

      isFullScreenJawaban: false,
      isFullScreenSoal: false,
      file_embed: "",

      url_petunjuk: "",
      url_soal: "",

      txt_materi: "",
      txt_simulasi: "",
      jumlah_jawaban: "",

      jadwal_data: null,

      countDownSoal: 3,
      is_soal_enable: true,
      is_soal_active: null,
      last_update_soal: "-",

      waktu_mulai: 0,
      sisa_waktu: 3600000,

      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",
      zoomLevel: 1, // Default zoom level for image
    };
  },
  computed: {
    isImage() {
      return this.file_embed && (this.file_embed.endsWith('.jpg') || this.file_embed.endsWith('.jpeg') || this.file_embed.endsWith('.png') || this.file_embed.endsWith('.gif'));
    },
  },
  mounted() {
    let self = this;
    self.id_jadwal = self.$route.params.id_jadwal;
    self.id_jadwal_detail = self.$route.params.id_jadwal_detail;
    self.tipe = self.$route.params.tipe;
    self.tipe_pengerjaan = self.$route.params.tipe_pengerjaan;
    self.getJadwalData();
  },
  methods: {
    zoomIn() {
      this.zoomLevel += 0.1; // Increase zoom level
    },
    zoomOut() {
      if (this.zoomLevel > 0.1) {
        this.zoomLevel -= 0.1; // Decrease zoom level, preventing zooming out too much
      }
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getJadwalData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-jadwal-data";
      var config = {
        method: "get",
        url: url,
        params: {
          id_jadwal_detail: self.id_jadwal_detail,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.jadwal_data = response_data.data;
            this.getDataDurasi();
            this.getInfoSimulasi();
            // this.fetchPetunjuk();
            // this.fetchSoal();
            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch(() => {

        });
    },
    getDataDurasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/get-durasi-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_batch: self.jadwal_data.jadwal_batch.id_jadwal_batch,
          id_jadwal_detail: self.jadwal_data.jadwal_detail.id,
          tipe: self.tipe,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.waktu_mulai = response_data.data;
            // var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            if (response_data.data == 0) {
              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Waktu Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
              });
              /*
              if (is_combo == "NO") {
                this.$router.push({
                  name: "kuesioner",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              } else {
                this.$router.push({
                  name: "kuesioner-combo",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              }
              */
              this.$router.push({
                name: "kuesioner-combo",
                params: {
                  id_jadwal: self.id_jadwal,
                  id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                  pin: self.jadwal_data.jadwal_detail.pin,
                },
              });
            }
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch(() => {

        });
    },
    getInfoSimulasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi/" + self.jadwal_data.jadwal_detail.simulasi_id;
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.txt_materi = response_data.data.level;
          self.txt_simulasi = response_data.data.nama_simulasi;
          self.jumlah_jawaban = response_data.data.simulasi_config.jumlah_jawaban;
        })
        .catch(() => {

        });
    },
    handleCountdownProgress(data) {
      let self = this;
      self.sisa_waktu = data.totalMinutes;
      var count_second = data.seconds;
      if (count_second % 30 == 0) {
        // interval 30 seconds.
        self.postAutoHistory();
      }

      if (count_second == 0) {
        self.checkDurasi();
      }
    },
    petunjukTrigger() {
      this.fetchPetunjuk();
      this.toggleTimerSoal();
    },
    toggleTimerSoal() {
      if (this.countDownSoal != 0) {
        this.timer = setInterval(() => {
          this.countDownSoal--;
          if (this.countDownSoal === 0) {
            clearInterval(this.timer);
            this.is_soal_enable = false;
            // countdown finished
          }
        }, 1000);
      }
    },
    fetchPetunjuk() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `petunjuk/${self.jadwal_data.jadwal_detail.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          if (responseData.data.data[0].format_file == "Video" || responseData.data.data[0].format_file == "PPT" || responseData.data.data[0].format_file == "Excel") {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            self.file_embed = responseData.data.data[0].link_yt;
          } else if (responseData.data.data[0].format_file == "Rich Text") {
            self.is_petunjuk_soal_rich_text = true;
            self.content_petunjuk_soal_rich_text = responseData.data.data[0].rich_text;
            self.file_embed = "";
          } else if (responseData.data.data[0].format_file == "PDF") {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            // self.file_embed = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + responseData.data.data[0].file;
            self.showFile(responseData.data.data[0].file, "Petunjuk");
          } else {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            self.file_embed = process.env.VUE_APP_BACKEND_URL + responseData.data.data[0].file;
          }
        })
        .catch(() => {
        });
    },
    async showFile(file_data, tipe) {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/generate-watermark-pdf";
      var config = {
        method: "post",
        url: url,
        data: {
          url_file: file_data,
          tipe: tipe,
          watermark: localStorage.session_username
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      await axios(config)
        .then((response) => {
          if (response?.data?.data?.file_url) {
            this.file_embed = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + response.data.data.file_url;
          }
        })
        .catch(() => {

        });

    },
    soalTrigger() {
      this.fetchSoal();
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${self.jadwal_data.jadwal_detail.id}/${self.tipe}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then(async (response) => {
          var responseData = response.data;
          if (responseData.data.data[0].tipe == "Video" || responseData.data.data[0].tipe == "PPT" || responseData.data.data[0].tipe == "Excel") {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            self.file_embed = responseData.data.data[0].link_yt;
          } else if (responseData.data.data[0].tipe == "Rich Text") {
            self.is_petunjuk_soal_rich_text = true;
            self.content_petunjuk_soal_rich_text = responseData.data.data[0].rich_text;
            self.file_embed = "";
          } else if (responseData.data.data[0].tipe == "PDF") {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            self.showFile(responseData.data.data[0].file, "Materi");
            // self.file_embed = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + responseData.data.data[0].file;
          } else {
            self.is_petunjuk_soal_rich_text = false;
            self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
            self.file_embed = process.env.VUE_APP_BACKEND_URL + responseData.data.data[0].file;
          }
          let data_soal = responseData.data.data[0];
          this.is_soal_active = data_soal;
          if (self.jumlah_jawaban == "1 Soal 1 Jawaban") {
            await self.getDataJawabanTerakhir(data_soal);
            await self.postDataSoalTrigger(data_soal);
          } else {
            await self.postDataSoalTrigger(data_soal);
          }
        })
        .catch(() => {
        });
    },
    async getDataJawabanTerakhir(data_soal) {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-terakhir";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          soal_data: JSON.stringify(data_soal),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      await axios(config)
        .then((response) => {
          let response_data = response.data.data;
          if (response_data.jawaban == "" || response_data.jawaban == null) {
            self.editorData = "<h3>Masukkan Jawaban Anda Disini.</h3>";
          } else {
            self.editorData = response_data.jawaban;
          }
          Swal.close();
        })
        .catch(() => {

          Swal.close();
        });
    },
    async postDataSoalTrigger(data_soal) {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-soal-trigger";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(data_soal),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      await axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.editorData = response_data.data.jawaban_history;
            self.last_update_soal = response_data.data.last_update;

          }
        })
        .catch(() => {

        });
    },
    postAutoHistory() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-auto-history-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)

        .catch(() => {

        });
    },
    postAnswer() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Berhasil simpan Jawaban.",
            });
          } else {

            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    checkDurasi() {
      let self = this;
      if (self.sisa_waktu == 0) {
        self.submitTestDone();
      }
    },
    submitTestDone() {
      let self = this;
      this.postAnswer();
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/submit-test-done";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_detail: self.id_jadwal_detail,
          sisa_waktu: self.sisa_waktu,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            // var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
            });
            // if (is_combo == "NO") {
            //   this.$router.push({
            //     name: "kuesioner",
            //     params: {
            //       id_jadwal: self.id_jadwal,
            //       id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
            //       pin: self.jadwal_data.jadwal_detail.pin,
            //     },
            //   });
            // } else {
            this.$router.push({
              name: "kuesioner-combo",
              params: {
                id_jadwal: self.id_jadwal,
                id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                pin: self.jadwal_data.jadwal_detail.pin,
              },
            });
            // }
          } else {

            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    halamanDetailJadwal() {
      this.$router.push({
        name: "detail-jadwal",
        params: {
          id: this.$route.params.id_jadwal,
        },
      });
    },

    toggleFullScreen(section) {
      if (section === "soal") {
        this.isFullScreenSoal = !this.isFullScreenSoal;
      } else if (section === "jawaban") {
        this.isFullScreenJawaban = !this.isFullScreenJawaban;
      }
    },
    // Method dari masing masing fungsi di dalam file ini
  },
};
</script>

<template>
  <div class="wrapper-jawaban">
    <div class="row">
      <div class="col-6">
        <button
          class="btn btn-warning mt-2 btn-sm"
          @click="halamanDetailJadwal()"
        ><i class="fa fa-arrow-left"></i> Kembali</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div
              class="judul"
              v-if="txt_materi && txt_simulasi"
            >
              <h4>{{ txt_materi }} - Materi {{ txt_simulasi }}</h4>
            </div>
          </div>
          <div class="col-6">
            <div class="text-end">
              <span class="h4">Sisa Waktu
                <span id="timer">
                  <vue-countdown
                    :time="waktu_mulai"
                    v-slot="{ hours, minutes, seconds }"
                    @progress="handleCountdownProgress"
                  > {{ hours }} : {{ minutes }} : {{ seconds }} </vue-countdown>
                </span>
              </span>
              <button
                class="btn btn-success ms-4 btn-md"
                v-on:click="submitTestDone"
              ><i class="fa fa-check"></i> Selesai Mengerjakan</button>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div :class="{
            'col-6 order-1': !isFullScreenSoal && !isFullScreenJawaban,
            'col-12 order-1': isFullScreenSoal && !isFullScreenJawaban,
            'col-12 order-2': !isFullScreenSoal && isFullScreenJawaban,
          }">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div>
                  <!-- Your existing buttons -->
                  <button
                    class="btn btn-md btn-primary"
                    @click="petunjukTrigger()"
                  >Petunjuk</button>
                  <button
                    class="btn btn-md btn-primary ms-2"
                    :disabled="is_soal_enable"
                    @click="soalTrigger()"
                  >Materi</button>
                </div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenSoal,
                      'btn btn-danger': isFullScreenSoal,
                    }"
                    @click="toggleFullScreen('soal')"
                  >
                    <i
                      v-if="!isFullScreenSoal"
                      class="fas fa-expand-alt"
                    ></i>
                    <i
                      v-else
                      class="fas fa-times"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="card-body mt-2">
                <div v-if="is_petunjuk_soal_rich_text == true">
                  <!-- 
                    <editor
                      api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                      :init="{
                        height: 600,
                        menubar: false,
                        plugins: [],
                        toolbar: '',
                        contextmenu_never_use_native: true,
                        paste_preprocess: function (plugin, args) {
                          args.content = '';
                        },
                      }"
                      v-model="content_petunjuk_soal_rich_text"
                    /> 
                  -->
                  <ckeditor
                    v-model="content_petunjuk_soal_rich_text"
                    :editor="editor"
                    :disabled="true"
                  ></ckeditor>
                </div>
                <div v-else>
                  <div v-if="file_embed">
                    <div v-if="isImage">
                      <div class="image-wrapper">
                        <img
                          :src="file_embed"
                          :style="{ transform: 'scale(' + zoomLevel + ')', transition: 'transform 0.25s ease' }"
                        />
                      </div>
                      <div class="zoom-controls">
                        <button
                          @click="zoomIn"
                          class="btn btn-primary"
                        >Zoom In</button>
                        <button
                          @click="zoomOut"
                          class="btn btn-primary"
                        >Zoom Out</button>
                      </div>
                    </div>

                    <!-- If file_embed is a PDF, use the PDF viewer -->
                    <div v-else>
                      <PDFViewer
                        :pdfUrl="file_embed"
                        :is-full-screen="isFullScreenSoal"
                      />
                    </div>
                  </div>
                  <div v-else>Silakan klik button petunjuk untuk memulai pengerjaan</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{
            'col-6 order-1': !isFullScreenJawaban && !isFullScreenSoal,
            'col-12 order-1': isFullScreenJawaban && !isFullScreenSoal,
            'col-12 order-2': !isFullScreenJawaban && isFullScreenSoal,
          }">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div class="d-flex align-items-center">
                  <span class="font-weight-semibold">
                    Lihat Panduan Lembar Jawaban dengan
                    <a
                      href="https://docs.google.com/document/d/e/2PACX-1vRidDF3m6B666ErPT7RvAFjjZijBEzDlEIcbWHtz7-BI-IEYheEMPS2_FVrKC_I2_-KAAz1tuyGUm_T/pub"
                      target="_blank"
                    > Klik Disini </a>
                  </span>
                </div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenJawaban,
                      'btn btn-danger': isFullScreenJawaban,
                    }"
                    @click="toggleFullScreen('jawaban')"
                  >
                    <i
                      v-if="!isFullScreenJawaban"
                      class="fas fa-expand-alt"
                    ></i>
                    <i
                      v-else
                      class="fas fa-times"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <!-- 
                  <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 600,
                      menubar: true,
                      plugins: ['table'],
                      paste_preprocess: function (plugin, args) {
                        args.content = '';
                      },
                      contextmenu_never_use_native: true,
                      toolbar:
                        'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    }"
                    v-model="editorData"
                  /> 
                -->
                <ckeditor
                  v-model="editorData"
                  :editor="editor"
                ></ckeditor>
                <div v-show="is_soal_active != null">
                  <div class="row mt-1 mb-1">
                    <div class="col-6">Last Update :
                      {{ last_update_soal == "-" || last_update_soal == null ? "-" :
                        fullDateTimeFormat(last_update_soal)
                      }}</div>
                    <div class="col-6 text-end">
                      <button
                        class="btn btn-sm btn-success"
                        v-on:click="postDataSoalTrigger()"
                      ><i class="mdi mdi-refresh"></i></button>
                    </div>
                  </div>
                  <div class="row">
                    <button
                      class="btn btn-md btn-primary"
                      v-on:click="postAnswer()"
                    ><i class="fa fa-save"></i> Simpan</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.no-margin {
  padding: 5 !important;
  background-color: rgb(243, 243, 243);
  border-color: aqua;
  border: 1px;
}

.card-header {
  border: 2px solid rgb(255, 255, 255);
  /* Customize the border color and other border properties */
}

.wrapper-jawaban {
  padding: 30px;
  /* Customize the border color and other border properties */
}

.ck-content {
  height: 500px;
}

.image-wrapper {
  max-height: 500px;
  /* Set the max height */
  overflow: auto;
  /* Enable scrolling if the content overflows */
  position: relative;
  border: 1px solid #ccc;
  /* Optional, to give the wrapper some visual border */
}

img {
  display: block;
  /* Remove the inline display property for better control */
  width: auto;
  /* Keep width auto to maintain aspect ratio */
  height: auto;
  /* Keep height auto to maintain aspect ratio */
}

.zoom-controls {
  margin-top: 10px;
}

button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

/* Styles for the full-screen mode */
</style>
